.page-wrapper .page-header {
    position: sticky !important;
    background-color: var(--theme-header-color);
}

.page-wrapper .page-body-wrapper .page-body {
    margin-top: 0px !important;
}

.page-wrapper .page-header .header-wrapper .nav-right > ul {
    flex-wrap: wrap;
}

.header-wrapper {
    justify-content: space-between;
}

.nav-item {
    cursor: pointer;
}

.btn {
    word-break: keep-all
}

.kanban-item .kanban-box {
    padding: 10px !important;
    margin-bottom: 10px;
}

.kanban-item .kanban-box * {
    pointer-events: none;
}

.kanban-box.important {
    background-color: #6bff5748 !important;
}

.kanban-box.incomplete {
    background-color: #f7d20077 !important;
}

.kanban-box.technician-notes {
    background-color: #ffa72370 !important;
}

.kanban-box.reject-message {
    background-color: #ff458356 !important;
}

.kanban-box .reject-message {
    font-size: 12px;
    font-weight: 500;
}

.color-black {
    color: #000;
}

.jkanban-container .kanban-container .kanban-item .kanban-box:hover {
    background:rgba($primary-color, 0.1) !important;
}

.jkanban-container .kanban-container .kanban-drag > div > div .react-kanban-column > div {
    transform-origin: top;
    transition: all 400ms ease-in-out;
}

.card-container-hide {
    transform: scaleY(0);
    height: 0px !important;
}

.ReactModal__Overlay {
    z-index: 40;
}

.customers {
    & .assigne {
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: rgb(58, 58, 58);
        font-size: 10px;
        color: #fff;
        transition: .2s;

        &:hover {
            -webkit-transform: translateY(-4px) scale(1.02);
            transform: translateY(-4px) scale(1.02);
            transition: .5s;
        }
    }
}

.form-control.mandatory {
    border-color: $pitch-color !important;
}

.input-note {
    color: #000;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
}

/* Hide the browser's default checkbox */
.form-checkbox-group input {
    opacity: 0 !important;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 2px;
    left: -22px;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #bbb;
    cursor: pointer;
}

.checkmark.danger{
    background-color: $danger-color;
}

.display-input-security-systems .checkmark {
    top: 7px;
}

.form-checkbox-group {
    position: relative;
}

/* When the checkbox is checked, add a green background */
.form-checkbox-group input:checked ~ .checkmark {
    background-color: var(--theme-default);
}

.form-checkbox-group input:checked ~ .checkmark.secondary {
    background-color: var(--theme-secondary);
}

.form-checkbox-group input:checked ~ .checkmark.danger {
    background-color: $danger-color;
}

/* Show the checkmark when checked */
.form-checkbox-group input:disabled ~ .checkmark {
    opacity: 0.5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.form-checkbox-group input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.form-checkbox-group .checkmark:after {
    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom-dropdown-item {
    padding: 12px 16px;
    text-decoration: none;
    font-size: 14px;
    background-color: transparent;
    color: #212529;
    display: block;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    width: 100%;
}

.custom-dropdown-item:hover {
    background-color: #eee !important;
}

.autocomplete-suggestion-container {
    display: block;
    position: absolute;
    top: 62px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10000;
    font-size: 15px !important;
    padding: 0px;
    box-shadow: 0 0 10px 0 #cccccc;
    color: #999;
    list-style: none;
    max-height: 300px;
    overflow-y: scroll;
}

.custom-date-dropdown-content {
    min-width: fit-content !important;
    max-height: 500px;
    overflow-y: scroll;
}

.autocomplete-suggestion-container li {
    padding: 0px 0;
    cursor: pointer;
    padding: 5px;
}

.autocomplete-suggestion-container li.active {
    background-color: var(--theme-default);
    color: #fff;
}

.drag-n-drop {
    height: 200px;
    border: 1px dashed #000;
    border-radius: 5px;
    & > div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.bigger-icon {
    font-size: 24px !important;
    cursor: pointer;
}

.react-datepicker {
    display: flex;
}

.react-datepicker__day--outside-month {
    opacity: 0.5;
}

.react-datepicker .react-datepicker__day--today {
    color: #000 !important;
    background-color: #fff !important;
    outline: 2px solid var(--theme-default) !important;
}

.react-datepicker .react-datepicker__day--selected {
    background-color: var(--theme-default) !important;
    color: #fff !important;
}

.react-datepicker .react-datepicker__day--today.react-datepicker__day--disabled,
.react-datepicker .react-datepicker__day--selected.react-datepicker__day--disabled {
    color: #ccc !important;
}

.card {
    .default-according {
        .btn-link {
            font-weight: 500;
            color: #2b2b2b;
            text-decoration: none;
        }
    }
}

.card-collapse-button {
    position: absolute;
    top: 12px;
    right: 40px;
    height: 30px;
    padding: 0px 20px !important;
    border-radius: 0px !important;
}

.sigCanvas-container,
.sigCanvas {
    height: 150px;
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
}

.task-image {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;
}

.file-content {
    .files {
        .file-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-right: 10px !important;
            margin-bottom: 10px !important;
            margin-left: 0px !important;

            .file-top {
                cursor: pointer;
            }
        }

        h6 {
            cursor: pointer;
            margin-bottom: 10px;
            word-break: break-all;
        }
    }
}

/* width */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DADADA;
    border-radius: 10px;
}

/* Handle */
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
    background: var(--theme-default);
}

.contract-group-item {
    cursor: pointer;
}

.contract-group-item:hover {
    background-color: #d7e2e9;
}

// DATATABLES
.rdt_TableCol_Sortable {
    & > div {
        white-space: normal !important;
    }
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(4) .rdt_TableCol_Sortable,
.rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(4) {
    justify-content: flex-start !important;
}

.rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(4) .rdt_TableCol_Sortable span {
    display: block;
}

.rdt_TableRow {
    cursor: pointer;
}

.rdt_TableRow:hover {
    background-color: $light-gray;
}

.no-cursor .rdt_TableRow {
    cursor: auto !important;
}

.rdt_TableRow:has(div.clickableRow) {
    background-color: $light-background;
}

.rdt_TableRow:has(.history-row-changed) {
    background-color: $danger-color;
}

.table-with-filters header {
    padding: 0px !important;
    min-height: auto !important;
}

.clickableRow {
    font-weight: 600;
    color: var(--theme-secondary);
}

nav.gKbhqU {
    flex-wrap: wrap;
}

.material-input-other {
    display: none;
}

.filter-icon {
    transition: transform 200ms ease-in-out;
}

.rotate-icon {
    transform: rotate(180deg);
}

.pagination-select {
    max-width: 80px;
    padding: 0.3rem 2rem 0.2rem 0.5rem;
}

.pagination {
    & .pagination-button {
        border: 1px solid #dee2e6;
        border-left: none;
        padding: 5px 12px;
        color: var(--theme-secondary);
        background-color: #fff;

        &:first-child {
            border-left: 1px solid #dee2e6;
        }

        &.active {
            color: #fff;
            background-color: var(--theme-secondary);
            border: 1px solid var(--theme-secondary);
        }
    }

    & .page-link {
        color: var(--theme-secondary);

        &.active {
            color: #fff;
            background-color: var(--theme-secondary);
            border: 1px solid var(--theme-secondary);
        }
    }
}

.contract-valid {
    padding: 7px 10px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;

    &.green {
        color: $success-color;
        background-color: $light-success-color;
    }

    &.red {
        color: $danger-color;
        background-color: $pitch-color;
    }
}

.form-label {
    font-weight: 500;
}

.red-alert {
    .form-label {
        color: $danger-color;
    }

    .form-control {
        border: 1px solid $danger-color;
    }
}

.field-history {
    color: $danger-color;
}

.red-background {
    .form-label {
        color: $danger-color;
    }

    .form-control {
        background-color: $danger-color;
        color: #fff;
    }
}

.red-color {
    color: $danger-color;
}

.display-input .red-alert {
    border: 1px solid $pitch-color;
    background: $pitch-color;
    padding: 10px;
    border-radius: 4px;
}

.display-label {
    font-weight: 500;
    padding-top: 0;
    padding-bottom: 0;
    display: inline-block;
    font-size: 15px;
    letter-spacing: .4px;
    line-height: 1.5;
}

.display-input {
    font-family: 'Roboto';
    width: 100%;
    color: #212529;
    font-size: 14px;
    font-weight: 400;
}

.display-input a {
    color: #212529;
}

.display-input-security-systems > div {
    border-top: 1px solid #3e414433;
}


// CALENDAR
.calendar-no-header-btns {
    margin: 50px 0px;

    .rbc-toolbar {
        .rbc-btn-group {
            display: none;
        }
    }
}

.rbc-btn-group {
    button:first-child:not(:last-child) {
        display: none !important;
    }

    button:not(:first-child):not(:last-child) {
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.rbc-toolbar-label {
    text-align: end !important;
}

.rbc-calendar {
    height: 100% !important;
}

.rbc-event-label {
    display: none;
}

.rbc-event.rbc-selected {
    background-color: var(--theme-default);
}

.agenda-table {
    border-collapse: separate;
    border-spacing: 0px;
}

.agenda-table thead th {
    border-top: 1px solid #000 !important;
}

.agenda-table th {
    border-bottom: 1px solid #000 !important;
    border-right: 1px solid #000 !important;
}

.agenda-table th:first-child {
    border-left: 1px solid #000 !important;
}

.agenda-table td {
    border-bottom: 1px solid #000 !important;
    border-right: 1px solid #000 !important;
    width: 12.5%;
}

.agenda-table.table > :not(:first-child) {
    border-top-width: 0px !important;
}

.agenda-table td .agenda-cell {
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-bottom: 1px solid var(--bs-table-border-color);
}

.reversed-agenda-table td {
    border: 1px solid #000 !important;
    width: auto !important;
}

.reversed-agenda-table th {
    border: 1px solid #000 !important;
    width: 12.5% !important;
}

.reversed-agenda-table.table > :not(:first-child) {
    border-top-width: 0px !important;
}

.reversed-agenda-table td .agenda-cell {
    cursor: pointer;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-bottom: 1px solid var(--bs-table-border-color);
}

.sticky-first-column tr>th:first-child,
.sticky-first-column tr>td:first-child {
    position: sticky;
    left: 0;
    background-color: #fff;
    // border: 1px solid #000;
}

.sticky-second-column tr>th:nth-child(2),
.sticky-second-column tr>td:nth-child(2) {
    position: sticky;
    left: 0;
    background-color: #fff;
    // border: 1px solid #000;
}


.calendar-standby {
    color: red;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
}

// HAMBURGER MENU
.hamburger-icon {
    background-color: transparent;
    border: none;
}

.right-header .mega-menu-container {
    background-color: #fff;
    border-radius: 0;
    box-shadow: 0 0 20px rgba(89,102,122,.1);
    padding-bottom: 20px;
    padding-left: 20px;
    padding-top: 10px;
}

.right-header .mega-menu-container .link-section>div h6 {
    margin-top: 20px;
}

.right-header .mega-menu-container .mega-box .svg-icon li {
    padding-left: 0;
}
.right-header .mega-menu-container .link-section li {
    padding: 7px 0 7px 15px;
    width: 100%;
}

.right-header .mega-menu-container div>div .liToA {
    display: inline-block;
    margin-bottom: 0;
}

.right-header .mega-menu-container .link-section .liToA {
    transition: all .3s ease;
}

.right-header .mega-menu-container .mega-box .svg-icon .liToA svg {
    margin-right: 5px;
    vertical-align: middle;
    width: 16px;
}

.select-search-dropdown {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.375rem;
    box-sizing: border-box;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 100;
}

.select-search-delete {
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0px;
}

.select-search-dropdown input {
    width: 100%;
}

.select-search-options {
    display: block;
    padding: 4px;
}

.select-search-options  {
    max-height: 300px;
    overflow-y: auto;
}

.select-search-options li:hover {
    background-color: var(--theme-default);
    color: #fff;
    opacity: 0.6;
}

.select-search-options li {
    line-height: 30px;
    cursor: pointer;
}

.select-search-options li.active {
    background-color: var(--theme-default);
    color: #fff;
}

.loader-danger {
    border: .2em solid $danger-color !important;
    border-bottom-color: transparent !important;
}

.loader-secondary {
    border: .2em solid var(--theme-secondary) !important;
    border-bottom-color: transparent !important;
}

.loader-box .loader-9 {
    background-color: var(--theme-secondary) !important;
}

.gmaps-location-marker {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 35px;
    transform: translateX(-50%);
}

.no-button,
.no-button:hover,
.no-button:active,
.no-button:focus {
    display: inline-block !important;
    background-color: transparent !important;
    border: none !important;
    color: #000 !important;
    padding: 0px !important;
    margin: 0px !important;
}

.contract-system-list > div {
    border-top: 1px solid #555;
}

.locked-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $warning-color;
    border-radius: 15px;
    padding: 20px 25px;
    margin-bottom: 15px;
    margin-top: -5px;
    font-weight: 600;
}

.red-text {
    color: #ff0000 !important;
    font-weight: 600 !important;
}

.attachments-container {
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 10px 10px 0px;
}

@media (max-width: 767px) {
    .page-wrapper .nav-right .nav-menus {
        margin-right: 0px !important;
    }

    .page-wrapper.compact-sidebar .left-header .mega-menu svg, .page-wrapper.compact-wrapper .left-header .mega-menu svg {
        stroke: #1d1e26;
        height: 16px;
        margin-right: 0;
    }

    .page-wrapper.compact-sidebar .right-header .mega-menu svg,
    .page-wrapper.compact-wrapper .right-header .mega-menu svg {
        stroke: #1d1e26;
        height: 16px;
        margin-right: 0;
    }

    .jkanban-container .kanban-container .kanban-drag > div > div {
        flex-direction: column;
    }

    .jkanban-container .kanban-container .kanban-drag > div > div .react-kanban-column:has(.react-kanban-card-skeleton) {
        display: none !important;
    }
}

@media (max-width: 575px) {
    .material-input-other {
        display: block;
    }
}

@media (max-width: 425px) {
    .single-service-item {
        padding: 0px !important;

        .card {
            margin-bottom: 0px !important;
        }
    }
}